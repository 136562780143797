import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Agenda() {
  return (
    <Container fluid className="agenda-section">
      <Row>
        <Col md={12} style={{ paddingTop: 50 }}>

          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
            <iframe
              src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0S8yC11ZEFuoInBFMGMgDDXRw4MIL51Rc_I0RjBxQxOVLuZRjiEP4HqfGZO3kQeaEyT9-kQKgE"
              style={{ border: "0", width: "100%", height: "90vh" }}
              frameBorder="0"
              allowFullScreen
              title="Minha Agenda"
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Agenda;
